/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; min-width: 340px; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.nav-spacer { flex: 1 1 auto; }
.mat-dialog-container { padding: 0px !important; }
.mat-dialog-content { margin: 0px !important; }
.mat-dialog-actions { margin-bottom: 0px !important; }
a {text-decoration: none; color: none;}
a span {color: #ffff !important;}
.activebutton { background-color: adjust-hue($color: #a06eea, $degrees: 0);}
